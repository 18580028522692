import {axios, React, useCallback, useEffect, useState} from '../../../imports/imports-packege';
import {useData} from "../../Context/ContextApp";
import {Button, PopoverInfo} from "../../../imports/imports-components";
import AppDesignStyle from "./AppDesignStyle.module.scss";

const CopyDesign = () => {
    const { data, updateData } = useData();

    let getUserQuery = new URLSearchParams(window.location.search);
    let getQueryKeitaroId = getUserQuery.get('keitaro_id');
    let getQueryOneSignalId = getUserQuery.get('onesignal_id');
    const [keitaroId, setKeitaroId] = useState('');
    const handlerKeitaroId = (query_keitaroId) => setKeitaroId(query_keitaroId);
    const [oneSignalId, setOneSignalId] = useState('');
    const handlerOneSignalId = (query_appId) => setOneSignalId(query_appId);

    useEffect(() => {
        if (getQueryKeitaroId) {
            handlerKeitaroId(getQueryKeitaroId);
        }

        if (getQueryOneSignalId) {
            handlerOneSignalId(getQueryOneSignalId)
        }
    }, [getQueryKeitaroId, getQueryOneSignalId]);



    const [idCopyPwa, setIdCopyPwa] = useState();
    const [urlPwaCopy, setUrlPwaCopy] = useState();

    const handleUrlPwaCopy = useCallback(() => {
        if (urlPwaCopy) {
            getIdCopyPwa(urlPwaCopy);
        }
    }, [urlPwaCopy])

    function getIdCopyPwa (pwaUrl) {
        let encodeUrlPwa = encodeURI(pwaUrl);

        axios.get(`https://fambanli.xyz/land/id?land_url=${encodeUrlPwa}`)
            .then((response) => {
                if (response.data.error === false) {
                    setIdCopyPwa(response.data.land_id);
                }
            })
            .then((res) => {
                console.log(res, 'res')
            })
            .catch(() => {
            alert('Нажаль не вдалось скопіювати вашу ПВА. Перевірте посилання на помилки');
        })
    }

   async function getConfigCopyPwa (idPwa) {
        try {
            // let response = await axios.get(`http://localhost:8181/api/get-config?keitaro_id=${idPwa}`);
            let response = await axios.get(`https://test.pwa-builder.com/api/get-config?keitaro_id=${idPwa}`);
            // let response = await axios.get(`https://pwa-builder.com/api/get-config?keitaro_id=${idPwa}`);
            let configData = JSON.parse(response.data.data);
            if (!configData.templateReview[0]['name']) {
                configData.templateReview = [];
                alert('Для вашої ПВА були згенеровані нові коментарі');
            }
            return configData;
        } catch (error) {
            console.log(`Error get config ${idPwa}: ${error}`)
        }
    }

    useEffect(() => {
        if (idCopyPwa) {
            getConfigCopyPwa(idCopyPwa)
                .then((configCopyPwa) => {
                    if (configCopyPwa) {
                        updateData(configCopyPwa);
                        updateData({
                            keitaroId: !data.keitaroId ? keitaroId : data.keitaroId,
                            oneSignalId: !data.oneSignalId ? oneSignalId : data.oneSignalId,
                            parseContents: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(`Error create pwa from copy config: ${error}`);
                });
        }
    }, [idCopyPwa])

    return (
        <div>
            <PopoverInfo
                TitleText="Копіювати ПВА"
                DescriptionText="Щоб скопіювати існуючу PWA, просто встав сюди посилання на неї."/>
            <input
                className={AppDesignStyle.InputField}
                placeholder="https://domain.pwa"
                onChange={(e) => setUrlPwaCopy(e.target.value)}
            />
            <div onClick={handleUrlPwaCopy}>
                <Button ButtonText="Copy" />
            </div>

        </div>
    );
};

export default CopyDesign;